/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/jquery-ui-dist@1.13.2/jquery-ui.min.js
 * - /npm/sweetalert2@11.7.5/dist/sweetalert2.min.js
 * - /npm/tablesorter@2.31.3/dist/js/jquery.tablesorter.combined.min.js
 * - /npm/smartmenus@1.2.1/dist/jquery.smartmenus.min.js
 * - /gh/rndme/download@1.4.7/download.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
